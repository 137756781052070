<template>
  <div d-flex class="job-post-container text-md-center">
    <v-layout>
      <v-flex>
        <v-alert
          outlined
          color="error"
          dismissible
          v-model="show_error"
          transition="scale-transition"
        >
          {{ $vuetify.lang.t("$vuetify.data_is_missing") }}
        </v-alert>
        <v-alert
          dismissible
          outlined
          color="success"
          icon="check_circle"
          v-model="delete_alert"
          >{{ $vuetify.lang.t("$vuetify.job_deleted") }}</v-alert
        >
        <v-stepper d-flex v-model="step_track">
          <v-stepper-header>
            <v-stepper-step
              class="stepper_round"
              editable
              @click="step_track == 1"
              step="1"
              >{{
                $vuetify.lang.t("$vuetify.job_post.basic_questions")
              }}</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step
              editable
              class="stepper_round"
              @click="step_track == 2"
              step="2"
              >{{
                $vuetify.lang.t("$vuetify.job_post.advance_questions")
              }}</v-stepper-step
            >
            <v-divider
              v-if="user && user.company && user.company.evaluations"
            ></v-divider>
            <v-stepper-step
              v-if="user && user.company && user.company.evaluations"
              editable
              class="stepper_round"
              @click="step_track == 3"
              step="3"
              >{{
                $vuetify.lang.t("$vuetify.job_post.form_evaluation")
              }}</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step
              class="stepper_round"
              editable
              @click="
                user.company.evaluations ? (step_track = 4) : (step_track = 3)
              "
              :step="user.company.evaluations ? 4 : 3"
              >{{ $vuetify.lang.t("$vuetify.job_post.review") }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-content step="1">
            <v-form
              @submit.prevent="next"
              color="primary"
              v-model="step_1_valid"
            >
              <v-row class="w-100">
                <v-col cols="6">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.job_post.job_title')"
                    v-model="job.title"
                    required
                    :rules="fieldRules"
                    color="primary"
                    class="pr-2"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.job_post.job_department')"
                    v-model="job.department"
                    :rules="fieldRules"
                    required
                    class="half-width-input"
                  ></v-text-field>
                </v-col>
              </v-row>
              <vue-html5-editor
                v-if="job"
                @change="saveDescription"
                :content="job.description"
                :height="500"
              ></vue-html5-editor>

              <v-row>
                <v-col cols="12" md="6" lg="4">
                  <v-menu
                    :close-on-content-click="true"
                    v-model="menu"
                    transition="scale-transition"
                    offset-y
                    :nudge-right="40"
                    max-width="290px"
                    min-width="290px"
                    hide-details
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        slot="activator"
                        :rules="fieldRules"
                        v-model="job.end_date"
                        prepend-icon="mdi-clock-outline"
                        readonly
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                        :label="
                          $vuetify.lang.t('$vuetify.job_post.expiry_date')
                        "
                        @input="job.end_date = parseDate(job.end_date)"
                        style="position: relative; top: 11px"
                      >
                        <template v-slot:append>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on">
                                mdi-information-outline
                              </v-icon>
                            </template>
                            Upon expiry the job would not be visible. Candidate
                            may still apply if they have the job link.
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      autosave
                      v-model="job.end_date"
                      @blur="job.end_date = parseDate(job.end_date)"
                      no-title
                      :allowed-dates="allowedDates"
                      hide-details
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    xs4
                    :label="$vuetify.lang.t('$vuetify.job_post.job_salary')"
                    v-model="job.salary"
                    :rules="fieldRules"
                    required
                    class="mt-4 ml-2 half-width-input"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-select
                    :label="$vuetify.lang.t('$vuetify.leaderboard')"
                    autocomplete
                    cache-items
                    required
                    :items="leaderboard"
                    :rules="fieldRules"
                    v-model="job.leaderboard"
                    class="mt-4 ml-2 half-width-input"
                  >
                    <template v-slot:append>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on"> mdi-information-outline </v-icon>
                        </template>
                        If disabled the job will not get referral point
                      </v-tooltip>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="6" lg="4" class="pt-0">
                  <v-select
                    :label="$vuetify.lang.t('$vuetify.job_post.positions')"
                    :rules="fieldRules"
                    :items="jobPositions"
                    v-model="job.positions"
                    autocomplete
                    cache-items
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" lg="4" class="pt-0">
                  <v-select
                    :label="$vuetify.lang.t('$vuetify.job_post.job_type')"
                    autocomplete
                    cache-items
                    required
                    :items="job_types"
                    :rules="fieldRules"
                    :item-text="'text'"
                    :item-value="'value'"
                    v-model="job.job_type"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" lg="4" class="pt-0">
                  <v-autocomplete
                    :label="$vuetify.lang.t('$vuetify.job_post.interviewers')"
                    v-model="job.hiring_manager"
                    :items="employee_list"
                    :loading="loading_manager"
                    cache-items
                    required
                    :rules="fieldRules"
                    :item-text="'email'"
                    :item-value="'_id'"
                    class="pr-2"
                  >
                    <template v-slot:append>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on"> mdi-information-outline </v-icon>
                        </template>
                        User can only view CV and add evaluation
                      </v-tooltip>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="4" class="pt-0">
                  <v-autocomplete
                    :label="$vuetify.lang.t('$vuetify.job_post.hiring_manager')"
                    v-model="job.hr_coordinator"
                    :items="employee_list"
                    :loading="loading_manager"
                    cache-items
                    required
                    :rules="fieldRules"
                    :item-text="'email'"
                    :item-value="'_id'"
                    multiple
                  >
                    <template v-slot:append>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on"> mdi-information-outline </v-icon>
                        </template>
                        Users becomes Job Admin, gets full access to the job
                      </v-tooltip>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!-- <v-col cols="12" md="6" lg="4" class="pt-0">
                  <v-autocomplete
                    :label="$vuetify.lang.t('$vuetify.location')"
                    v-model="job.location"
                    :items="locations"
                    :rules="fieldRules"
                  ></v-autocomplete>
                </v-col> -->

                <v-col cols="12" md="6" lg="4" class="pt-0">
                  <v-select
                    cache-items
                    required
                    label="Interview Questions"
                    :item-text="'title'"
                    :item-value="'value'"
                    :items="interviewList"
                    :loading="interviewLoading"
                    v-model="job.interview_id"
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <div class="text-left">
                    <h3>
                      Locations
                      <v-btn icon class="secondary ml-3" small>
                        <v-icon color="white" @click="locationModal = true"
                          >mdi-plus</v-icon
                        >
                      </v-btn>
                    </h3>
                    <template v-if="job.location && job.location.length">
                      <v-chip
                        v-for="location in jobLocations"
                        :key="location"
                        class="ma-2"
                        close
                        @click:close="
                          job.location = job.location.filter(
                            (loc) => loc !== location
                          )
                        "
                      >
                        {{ location }}
                      </v-chip>
                    </template>
                  </div>
                </v-col>
              </v-row>
              <div class="mt-5 text-right">
                <v-btn type="submit" class="secondary"
                  >{{ $vuetify.lang.t("$vuetify.next") }}
                </v-btn>
              </div>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-flex v-if="!current_question">
              <h3>
                {{ $vuetify.lang.t("$vuetify.job_post.add_custom_questions") }}
              </h3>
              <v-row align="center">
                <v-col cols="10">
                  <v-select
                    :items="question_options"
                    v-model="select"
                  ></v-select>
                </v-col>
                <v-col cols="2">
                  <v-btn @click="addQuestion" class="primary">{{
                    $vuetify.lang.t("$vuetify.add")
                  }}</v-btn>
                </v-col>
              </v-row>
            </v-flex>
            <v-flex v-if="current_question">
              <v-form @submit.prevent="addQuestionFinal">
                <h3>{{ $vuetify.lang.t("$vuetify.job_post.new_question") }}</h3>
                <v-flex>
                  <v-text-field
                    v-model="current_question.question"
                    required
                    :rules="fieldRules"
                    :label="$vuetify.lang.t('$vuetify.job_post.question_text')"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex
                  d-inline-flex
                  w-100
                  v-if="current_question.options"
                  class="mt-2"
                >
                  <v-form
                    ref="optionsForm"
                    style="display: flex"
                    @submit.prevent="addOption"
                  >
                    <v-text-field
                      v-model="option"
                      required
                      :rules="optionRules"
                      :label="$vuetify.lang.t('$vuetify.option')"
                    >
                    </v-text-field>
                    <v-btn type="submit" color="primary" small>{{
                      $vuetify.lang.t("$vuetify.add_option")
                    }}</v-btn>
                  </v-form>
                </v-flex>
                <v-flex
                  v-if="
                    current_question.options && current_question.options.length
                  "
                  class="text-md-left"
                >
                  <h4>
                    {{ $vuetify.lang.t("$vuetify.job_post.current_option") }}
                  </h4>
                  <v-chip
                    v-for="(option_text, index) in current_question.options"
                    :key="option_text"
                    close
                    @input="removeOption(index)"
                    >{{ option_text }}</v-chip
                  >
                </v-flex>
                <v-flex class="mt-2">
                  <v-checkbox
                    v-model="current_question.required"
                    label="Required"
                  >
                  </v-checkbox>
                </v-flex>

                <v-btn rounded color="primary" type="submit" class="mr-5">{{
                  $vuetify.lang.t("$vuetify.add_question")
                }}</v-btn>
                <v-btn rounded color="danger" @click="cancelQuestionFinal">{{
                  $vuetify.lang.t("$vuetify.cancel")
                }}</v-btn>
              </v-form>
            </v-flex>
            <v-flex v-if="job.questions && job.questions.length">
              <v-data-table
                :headers="table_headers"
                :items="job.questions"
                item-key="question"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn @click="removeQuestion(item)" class="mr-3">{{
                    $vuetify.lang.t("$vuetify.delete")
                  }}</v-btn>
                  <v-btn @click="editQuestion(item)">
                    {{ $vuetify.lang.t("$vuetify.edit") }}
                  </v-btn>
                </template>
              </v-data-table>
            </v-flex>
            <div class="text-right mt-5">
              <v-btn class="secondary mt-5" @click="next">
                {{ $vuetify.lang.t("$vuetify.next") }}
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content
            v-if="user && user.company && user.company.evaluations"
            step="3"
          >
            <v-layout>
              <v-btn
                @click="useExistingEvaluation = true"
                color="secondary"
                class="mx-5"
              >
                {{
                  $vuetify.lang.t("$vuetify.job_post.use_existing_evaluation")
                }}
              </v-btn>
              <v-btn color="secondary" @click="addNewEvaluationModal = true">{{
                $vuetify.lang.t("$vuetify.job_post.add_new_evaluation")
              }}</v-btn>
            </v-layout>
            <v-flex v-if="useExistingEvaluation">
              <v-select
                :label="
                  $vuetify.lang.t('$vuetify.job_post.select_from_existing')
                "
                :items="evaluationForms"
                :item-value="'_id'"
                :item-text="'name'"
                @change="assignEvaluationForm"
              ></v-select>
            </v-flex>
            <v-dialog light v-model="addNewEvaluationModal" width="500">
              <evaluationForm
                @submit="assignEvaluationForm"
                @cancel="addNewEvaluationModal = false"
              ></evaluationForm>
            </v-dialog>
            <v-flex v-if="job.evaluation_id">
              {{
                $vuetify.lang.t("$vuetify.job_post.evaluation_form_selected")
              }}: {{ selected_form.name }}
            </v-flex>

            <div class="mt-5 text-right">
              <v-btn outlined color="secondary" class="mt-5" @click="next()">{{
                $vuetify.lang.t("$vuetify.next")
              }}</v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content :step="user.company.evaluations ? 4 : 3">
            <v-flex>
              <div style="display: inline-block">
                <h4 class="text-md-left">
                  {{
                    $vuetify.lang.t("$vuetify.job_post.publish_on_following")
                  }}
                </h4>
                <v-flex
                  class="sites mt-2"
                  style="justify-content: center; align-items: flex-start"
                >
                  <v-switch
                    disabled
                    v-model="job.publishing_site.website"
                  ></v-switch>
                  <div>
                    {{ $vuetify.lang.t("$vuetify.job_post.company_website") }}
                  </div>
                </v-flex>
                <v-flex class="sites">
                  <v-switch disabled></v-switch>
                  <img
                    style="height: 70px; width: 150px"
                    src="@/assets/logo_indeed.png"
                  />
                </v-flex>
                <v-flex class="sites">
                  <v-switch disabled></v-switch>
                  <img
                    style="height: 70px; width: 150px"
                    src="@/assets/logo_monster.png"
                  />
                </v-flex>
                <v-flex class="sites">
                  <v-switch disabled></v-switch>
                  <img
                    style="height: 70px; width: 150px"
                    src="@/assets/logo_rozee.png"
                  />
                </v-flex>
              </div>

              <h4>{{ $vuetify.lang.t("$vuetify.job_post.save_as_draft") }}</h4>
              <h4 v-if="job.status">
                {{ $vuetify.lang.t("$vuetify.job_post.current_status") }}
                {{ job.status }}
              </h4>
              <div class="mt-5"></div>
              <v-btn
                color="secondary"
                :disabled="loading || loading1"
                :loading="loading"
                @click="saveJob('draft')"
              >
                {{ $vuetify.lang.t("$vuetify.draft") }}
              </v-btn>
              <v-btn
                class="mx-5"
                color="secondary"
                :loading="loading1"
                :disabled="loading1 || loading"
                @click="saveJob('publish')"
              >
                {{ $vuetify.lang.t("$vuetify.publish") }}
              </v-btn>
              <v-btn
                v-if="showDeleteBtn"
                @click="showConfirmationModal = true"
                :loading="deleting"
                color="secondary"
              >
                {{ $vuetify.lang.t("$vuetify.delete_job") }}
              </v-btn>
            </v-flex>
          </v-stepper-content>
        </v-stepper>
      </v-flex>
    </v-layout>

    <v-dialog v-model="locationModal" max-width="600px">
      <Locations
        @closeModal="locationModal = false"
        @confirm="addLocations"
        :available-locations="job.location || []"
      />
    </v-dialog>

    <v-dialog v-model="showConfirmationModal" max-width="500px">
      <v-card class="pa-2">
        <v-card-title
          ><span class="headline">
            {{ $vuetify.lang.t(`$vuetify.popup.posted_job.delete_job`) }}
          </span></v-card-title
        >
        <v-card-actions class="text-right">
          <v-btn
            color="secondary"
            class="mx-3"
            outlined
            @click="showConfirmationModal = false"
          >
            {{ $vuetify.lang.t("$vuetify.cancel") }}
          </v-btn>

          <v-btn
            @click="deleteJob"
            :loading="deleting"
            color="secondary"
            class="mx-0 elevation-0"
          >
            {{ $vuetify.lang.t(`$vuetify.delete`) }}
            {{ $vuetify.lang.t("$vuetify.job") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EvaluationForm from "@/components/evaluation/evaluation-form";
import DQ from "@/helpers/question-helper";

import moment from "moment";
import * as _ from "lodash";
import { mapState } from "vuex";
import Locations from "@/components/add-locations/locations";

export default {
  name: "job-post",

  components: {
    EvaluationForm,
    Locations,
  },
  data() {
    return {
      locationModal: false,
      menu: false,
      deleting: false,
      fieldRules: [
        (val) => {
          if (val === undefined || val === null || val === false || val === "")
            return "This Field is required";
          return true;
        },
      ],
      optionRules: [(v) => !!v || "Atleast 2 Options are required"],
      step_1_valid: false,
      step_2_valid: false,
      step_3_valid: false,
      search: null,
      step_track: 1,
      question_options: ["text"],
      select: "text",
      current_question: null,
      option: "",
      loading1: false,
      loading: false,
      addNewEvaluationModal: false,
      loading_manager: false,
      show_error: false,
      delete_alert: false,
      useExistingEvaluation: false,
      selected_form: "",
      evaluationForms: [],
      employee_list: [],
      job: {
        title: null,
        description: "",
        end_date: null,
        department: null,
        bonus: null,
        questions: [],
        publishing_site: {
          website: true,
        },
        evaluation: "",
        hiring_manager: "",
        hr_coordinator: [],
        job_type: null,
        location: null,
        _id: null,
        evaluation_id: null,
        salary: null,
        leaderboard: null,
        positions: 0,
        interview_id: null,
      },
      table_headers: [
        { text: this.$vuetify.lang.t("$vuetify.question"), value: "question" },
        { text: this.$vuetify.lang.t("$vuetify.type"), value: "type" },
        { text: this.$vuetify.lang.t("$vuetify.required"), value: "required" },
        { text: this.$vuetify.lang.t("$vuetify.action"), value: "action" },
      ],
      evaluation_headers: [
        { text: this.$vuetify.lang.t("$vuetify.criteria"), value: "criteria" },
        { text: this.$vuetify.lang.t("$vuetify.points"), value: "points" },
        { text: this.$vuetify.lang.t("$vuetify.action"), value: "action" },
      ],
      job_types: [
        { text: this.$vuetify.lang.t("$vuetify.part_time"), value: 0 },
        { text: this.$vuetify.lang.t("$vuetify.full_time"), value: 1 },
        { text: this.$vuetify.lang.t("$vuetify.hourly"), value: 2 },
        { text: this.$vuetify.lang.t("$vuetify.weekly"), value: 3 },
        { text: this.$vuetify.lang.t("$vuetify.remotely"), value: 4 },
      ],
      leaderboard: [
        { text: this.$vuetify.lang.t("$vuetify.enable"), value: "enable" },
        { text: this.$vuetify.lang.t("$vuetify.disable"), value: "disable" },
      ],
      showConfirmationModal: false,
      canDelete: false,
      interviews: [],
      interviewLoading: false,
    };
  },
  computed: {
    ...mapState(["user", "locations"]),
    interviewList() {
      return [
        { title: "No Interview", value: null },
        ...this.interviews.map((interview) => ({
          title:
            interview.title +
            " ~ " +
            moment(interview.created_at).format("MMMM DD, YYYY"),
          value: interview._id,
        })),
      ];
    },
    jobPositions() {
      let arr = [];
      for (let i = 1; i < 100; i++) {
        arr.push(i);
      }
      return arr;
    },
    showDeleteBtn() {
      return this.$route.params.id && this.canDelete;
    },
    jobPayload() {
      let obj = { ...this.job };
      if (obj.location && obj.location.length && Array.isArray(obj.location)) {
        obj.location = obj.location.join(",");
      }
      return obj;
    },
    jobLocations() {
      if (this.job.location && Array.isArray(this.job.location)) {
        const locs = [...this.job.location];
        if (locs.length > 1) {
          locs.splice(locs.length - 1, 1);
        }
        return locs;
      }

      return [];
    },
  },
  watch: {},
  async created() {
    this.fetchEmployees();
    this.fetchInterviews();

    await this.fetchEvaluationForm();
    if (this.$route.params.id) {
      this.fetchJob();
    }
  },

  methods: {
    addLocations(cities) {
      this.job.location = cities;
      this.locationModal = false;
    },
    allowedDates(date) {
      let current_date = moment();
      if (moment(date).isAfter(current_date, "day")) {
        return true;
      } else {
        return false;
      }
    },
    parseDate(date) {
      if (!date) {
        return;
      }
      let d = moment(date).format("YYYY-MM-DD");
      return d;
    },
    saveDescription(e) {
      this.job.description = e;
    },
    next() {
      if (!this.step_1_valid || this.job.description == "") {
        this.show_error = true;
        return;
      }
      if (this.step_1_valid) {
        this.step_track++;
      } else if (this.step_track == 2 || this.step_track == 3) {
        this.step_track++;
      }
    },
    addQuestion() {
      this.current_question = DQ[this.select]();
    },
    addOption() {
      if (this.option != "") {
        this.current_question.options.push(this.option);
        this.$refs.optionsForm.reset();
      }
    },
    removeOption(index) {
      this.current_question.options.splice(index, 1);
    },
    cancelQuestionFinal() {
      this.current_question = null;
    },
    addQuestionFinal() {
      if (this.current_question.question == "") {
        return;
      }
      if (
        this.current_question.type == "select" ||
        this.current_question.type == "radio"
      ) {
        if (
          !this.current_question.options ||
          this.current_question.options.length < 2
        ) {
          return;
        }
      }
      this.job.questions.push(this.current_question);
      this.current_question = null;
    },
    removeQuestion(item) {
      let index = _.findIndex(this.job.questions, (q) => {
        return q.question == item.question;
      });
      this.job.questions.splice(index, 1);
    },
    editQuestion(item) {
      let index = _.findIndex(this.job.questions, (q) => {
        return q.question == item.question;
      });
      this.job.questions.splice(index, 1);
      this.current_question = item;
    },
    cancelJob() {
      this.job = {
        title: null,
        description: null,
        department: null,
        bonus: null,
        questions: [],
        publishing_site: {
          website: true,
        },
      };
    },
    async saveJob(status) {
      try {
        if (!this.step_1_valid || this.job.description == "") {
          this.show_error = true;
          return;
        }
        this.job.status = status;
        if (status == "draft") {
          this.loading = true;
        }

        if (status == "publish") {
          this.loading1 = true;
        }

        console.log(this.jobPayload);

        if (this.$route.params.id) {
          this.editJob();
          return;
        }

        await this.axios.post("job", this.jobPayload);
        this.loading1 = false;
        this.loading = false;
        this.$router.push("/posted-jobs");
      } catch (error) {
        console.log(error);
        this.loading1 = false;
        this.loading = false;
      }
    },
    async editJob() {
      try {
        await this.axios.put(`/job/${this.$route.params.id}`, this.jobPayload);
        this.loading1 = false;
        this.loading = false;
        this.$router.push("/posted-jobs");
      } catch (error) {
        console.log(error);
        this.loading1 = false;
        this.loading = false;
      }
    },
    async fetchEvaluationForm() {
      try {
        const resp = await this.axios.get(
          `/evaluation-form?company_id=${this.user.company_id}`
        );
        const { data } = resp.data;
        this.evaluationForms = data;
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async deleteJob() {
      try {
        this.deleting = true;
        await this.axios.delete(`/job/${this.$route.params.id}`);
        this.delete_alert = true;
        this.deleting = false;
        this.$router.push("/posted-jobs");
      } catch (error) {
        this.deleting = false;
        console.log(error);
      }
    },
    async fetchEmployees() {
      try {
        this.loading_manager = true;
        const resp = await this.axios.get(
          `/user?company_id=${this.user.company_id}`
        );
        const { data } = resp.data;
        this.employee_list = data;
        this.loading_manager = false;
      } catch (error) {
        this.loading_manager = false;
        console.log(error);
      }
    },
    async fetchJob() {
      try {
        const resp = await this.axios.get(
          `/job/${this.$route.params.id}?w=applications`
        );
        const res = resp.data;
        this.canDelete = res.applications.length === 0;
        // _.forEach(this.job, (prop, value) => {
        //   this.job[value] = res[value] ? res[value] : prop;
        // });
        for (const key in this.job) {
          if (key === "location") {
            if (res[key] && !Array.isArray(res[key])) {
              const locArr = res[key].split(",");
              this.job[key] = locArr ?? [];
            } else {
              this.job[key] = res[key] ?? [];
            }
          } else {
            this.job[key] = res[key] ? res[key] : null;
          }
        }

        if (!this.job.positions) {
          this.job.positions = 1;
        }

        this.job["end_date"] = this.parseDate(this.job["end_date"]);

        if (this.job.evaluation_id) {
          this.selected_form = this.evaluationForms.find((ev) => {
            return ev._id == this.job.evaluation_id;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async assignEvaluationForm(obj) {
      if (obj._id) {
        this.evaluationForms.push(obj);
        this.selected_form = obj;
        this.job.evaluation_id = obj._id;
      } else {
        this.selected_form = this.evaluationForms.find((ev) => {
          return ev._id == obj;
        });
        this.job.evaluation_id = obj;
      }
    },
    async fetchInterviews() {
      try {
        this.interviewLoading = true;
        const resp = await this.axios.get(
          `/interview?desc=created_at&company_id=${this.user.company_id}`
        );
        this.interviews = resp.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.interviewLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.job-post-container {
  width: 100%;
  padding: 4rem 4rem !important;

  .stepper_round {
    span {
      color: #fff;
    }
  }
  .vue-html5-editor {
    z-index: 1 !important;
    .toolbar {
      z-index: 2 !important;
      ul {
        .icon {
          font-size: 1em;
        }
      }
    }
  }
  .sites {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}
</style>
