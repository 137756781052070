import { render, staticRenderFns } from "./job-post.vue?vue&type=template&id=1008b6d2&scoped=true&"
import script from "./job-post.vue?vue&type=script&lang=js&"
export * from "./job-post.vue?vue&type=script&lang=js&"
import style0 from "./job-post.vue?vue&type=style&index=0&id=1008b6d2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1008b6d2",
  null
  
)

export default component.exports