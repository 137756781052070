<template>
  <v-card>
    <v-card-title>Locations</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete
            :items="countries"
            v-model="country_id"
            label="Countries"
            :loading="loading.country"
            :disabled="!countries.length || disable_country"
            @input="fetchCities"
            hide-details
            :item-text="'name'"
            :item-value="'_id'"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="6">
          <v-autocomplete
            :items="cities"
            label="Select Cities"
            :loading="loading.city"
            :disabled="!cities.length"
            @change="addToLocations"
            hide-details
            :item-text="'name'"
            :item-value="'_id'"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <div>
        <v-chip
          v-for="location in locations"
          :key="location"
          class="ma-2"
          close
          @click:close="locations = locations.filter(loc => loc !== location)"
        >
          {{ location }}
        </v-chip>
      </div>
      <div class="text-right mt-5">
        <v-btn color="error" @click="$emit('closeModal')" class="mx-3"
          >Cancel</v-btn
        >
        <v-btn color="secondary" @click="confirmHandler" class="mx-3"
          >Confirm</v-btn
        >
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "add-locations",
  props: {
    availableLocations: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: {
        country: false,
        city: false
      },
      countries: [],
      cities: [],
      locations: [],
      country_name: "",
      disable_country: false,
      country_id: ""
    };
  },
  methods: {
    set_loading(type, loading) {
      this.loading[type] = loading;
    },
    async fetchCountries() {
      this.set_loading("country", true);
      const resp = await this.axios.get("countries");
      this.countries = resp.data.data.map(c => {
        return {
          ...c,
          name: this.capitalizeFirstLetter(c.name)
        };
      });
      this.set_loading("country", false);
      return;
    },
    async fetchCities(country_id) {
      if (!country_id) return;
      this.set_loading("city", true);

      this.country_name = this.countries.find(c => c._id == country_id).name;
      this.cities = [
        {
          _id: "all",
          name: `Anywhere in ${this.country_name}`
        }
      ];
      const resp2 = await this.axios.get(`cities?country_id=${country_id}`);
      this.cities = [...this.cities, ...resp2.data.data];
      this.set_loading("city", false);
    },
    addToLocations(city_id) {
      const idx = this.cities.findIndex(c => c._id === city_id);
      if (idx !== -1) {
        const name = this.cities[idx].name;
        const exist = this.locations.includes(name);
        if (!exist) this.locations.push(name);
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    confirmHandler() {
      if (!this.locations.length)
        return this.$notify({
          group: "backend-error",
          title: "Error",
          text: "Please Add any city to continue",
          type: "error",
          duration: 5000
        });
      this.$emit("confirm", [...this.locations, this.country_id]);
    }
  },
  async created() {
    await this.fetchCountries();
    if (this.availableLocations.length > 0) {
      const locations = [...this.availableLocations];
      if (locations.length > 1) {
        this.disable_country = true;

        this.country_id = locations[locations.length - 1];
        locations.splice(locations.length - 1, 1);
        this.fetchCities(this.country_id);
      }

      this.locations = [...locations];
    }
  }
};
</script>

<style lang="scss" scoped></style>
