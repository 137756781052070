<template>
  <v-card>
    <v-form ref="form" v-model="valid">
      <v-layout column class="pa-4">
        <h2 class="h3">
          {{ $vuetify.lang.t("$vuetify.job_post.add_evaluation_form") }}
        </h2>
        <v-flex style="display: flex;">
          <v-text-field
            v-model="evaluation_name"
            required
            :rules="[rules.fieldRules]"
            :label="$vuetify.lang.t('$vuetify.name')"
          >
          </v-text-field>
        </v-flex>
        <v-row align="center">
          <v-col cols="5">
            <v-text-field
              v-model="object.criteria"
              required
              :rules="[rules.fieldRules]"
              :label="$vuetify.lang.t('$vuetify.criteria')"
            >
            </v-text-field>
          </v-col>
          <v-col cols="5">
            <v-text-field
              class="ml-3"
              v-model="object.points"
              required
              type="number"
              :rules="[rules.fieldRules, rules.maxNumberField]"
              :label="$vuetify.lang.t('$vuetify.points')"
            >
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              small
              @click="addEvaluation"
              class="secondary"
              :disabled="!object.criteria && !object.points"
            >
              {{ $vuetify.lang.t("$vuetify.add") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-flex v-if="evaluation && evaluation.length">
          <v-data-table
            :headers="evaluation_headers"
            :items="evaluation"
            item-key="citeria"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-btn small color="error" @click="removeEvaluation(item)">
                {{ $vuetify.lang.t("$vuetify.delete") }}
              </v-btn>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <v-layout class="pa-2" justify-space-around justify-center align-center>
        <v-flex xs4>
          <v-btn
            :loading="loading"
            small
            dark
            color="blue"
            @click="saveEvaluationForm"
          >
            {{ $vuetify.lang.t("$vuetify.save") }}
          </v-btn>
        </v-flex>
        <v-flex xs4>
          <v-btn outlined color="secondary" small @click="cancel">
            {{ $vuetify.lang.t("$vuetify.cancel") }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

import * as _ from "lodash";

export default {
  data() {
    return {
      valid: false,
      loadingState: false,
      loading: false,
      evaluation_name: "",
      search: null,
      evaluation: [],
      object: {
        criteria: "",
        points: 0,
        action: "",
      },
      rules: {
        fieldRules: (v) => !!v || "Field is required",
        maxNumberField: (v) =>
          Number(v) < 6 || "Number cannot be greater then 5",
      },
      evaluation_headers: [
        { text: this.$vuetify.lang.t("$vuetify.criteria"), value: "criteria" },
        { text: this.$vuetify.lang.t("$vuetify.points"), value: "points" },
        { text: this.$vuetify.lang.t("$vuetify.action"), value: "action" },
      ],
      taggedUsers: [],
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    submit() {},
    clear() {
      this.$refs.form.resetValidation();
    },
    cancel() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$emit("cancel");
    },
    addEvaluation() {
      this.evaluation.push(this.object);
      this.object = {
        criteria: "",
        points: 0,
        action: "",
      };
      this.$refs.form.resetValidation();
    },
    async saveEvaluationForm() {
      try {
        this.loading = true;
        let data = {
          name: this.evaluation_name,
          form: this.evaluation,
          company_id: this.user.company_id,
        };

        const resp = await this.axios.post("evaluation-form", data);
        this.$emit("submit", resp.data);
        this.cancel();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    removeEvaluation(item) {
      let index = _.findIndex(this.evaluation, (q) => {
        return q.criteria == item.criteria;
      });
      this.evaluation.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.bordered {
  border: 1px solid #ddd;
  border-radius: 0.2em;
}
.outline {
  border: 1px solid !important;
}
.input-group__input {
  border: 1px solid !important;
}
</style>
