export default {
  text() {
    return {
      question: "",
      required: false,
      type: "text",
    };
  },
  select() {
    return {
      question: "",
      options: [],
      type: "select",
      required: false,
    };
  },
  checkbox() {
    return {
      question: "",
      required: false,
      type: "checkbox",
    };
  },
  radio() {
    return {
      question: "",
      options: [],
      required: false,
      type: "radio",
    };
  },
};
